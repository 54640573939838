akwaaba-forms-homepage {
    display: block;
}


/* START :: TAILWIND CSS */

@import '../tailwind-output/homepage.scss';

/* END :: TAILWIND CSS */


/* START :: SWIPERJS CSS */

@import '../../swiperjs/main.scss';
.swiper {
    /*width: 600px;*/
    height: 200px;
}


/* END :: SWIPERJS CSS */